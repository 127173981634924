/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import styles from "./FcHoverImage.module.css";
import doctor from "../../assets/images/doctor1.png";
import DepartmentImage_1 from "../../assets/images/departmentimage1.png";

import { Button, Typography } from "@mui/material";

function FcHoverImage({
  image,
  describtion,
  width,
  height,
  btnName,
  onButtonClick,
  objectFit,
}) {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={styles.card}
      style={{ width: width, height: height }}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}>
      <img
        src={image}
        className={styles.CardImg}
        style={{ objectFit: objectFit }}
        onError={(e) => (e.target.src = "/doctors/no_image.jpg")}
      />
      {hovered && (
        <div className={styles.CarBody}>
          <div>
            {/* <Button
              variant="contained"
              onClick={onButtonClick}
              sx={{
                marginBottom: "14px",
                backgroundColor: "white",
                width: "100%",
                Height: "70px",
                borderRadius: "44px",
                "&:hover": {
                  backgroundColor: "white", // Set the desired hover background color
                },
              }}>
              <Typography
                color="black"
                fontFamily="Manrope"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                textAlign="center"
                padding="7px"
                sx={{ textTransform: "none" }}>
                {btnName}
              </Typography>
            </Button> */}
            <Typography
              fontSize="15px"
              fontFamily="Manrope"
              fontWeight="700"
              color="001A6E"
              textAlign="justify"
              sx={{
                paddingLeft: "6px",
                padding: "20px",
                WebkitTextStroke: " white", // Add text stroke (outline)
                textStroke: " white", // For other browsers, in case supported
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "18px",
                },
              }}>
              {describtion}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default FcHoverImage;
