import React from "react";
import style from "./FamilyClinic.module.css";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Avatar, Chip } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import AboutImage from "../../assets/images/fc-aboutimg.png";
import CheckCircleIcon from "../../assets/images/fc-checkcircle-icon.png";
import FcHoverImage from "../../components/FcHoverImage/FcHoverImage";

const services = [
  { label: "Disease Prevention" },
  { label: "Improved Quality of Life" },
  { label: "Early Diagnosis & Treatment" },
  { label: "Emergency Care & Trauma Services" },
];

const departments = [
  {
    title: "ENT (Ear, Nose, and Throat)",
    // services: ["Oncology", "Cardiology", "Neurology"],
    image: "/fc-departments/speciality-care.png",
    describtion:
      "At GG Family Clinic, our ENT specialists treat ear, nose, throat, head, and neck issues, including allergies, sinusitis, hearing loss, and snoring. We provide expert diagnosis, medical care, and surgical interventions using advanced technology for personalized treatment.",
  },
  {
    title: "Dental Care",
    // services: ["General Dentistry", "Specialized Dentistry"],
    image: "/fc-departments/dental.png",
    describtion:
      "We offer complete dental care, from routine check-ups and cleanings to advanced treatments like root canals, implants, orthodontics, and cosmetic dentistry. Our team ensures high-quality, patient-focused care for a healthy smile.",
  },
  {
    title: "Gynaecology",
    // services: ["Cardiology", "Oncology", "Neurology"],
    image: "/fc-departments/emergency.png",
    describtion:
      "Our Gynecology department provides comprehensive care, including routine exams, pregnancy support, fertility treatments, and menopause management. We prioritize women’s health at every stage of life with expert, compassionate care.",
  },

  {
    title: "General Medicine",
    // services: ["Psychiatry", "Psychology"],
    image: "/fc-departments/mentalhealth.png",
    describtion:
      "Our General Medicine department manages acute and chronic illnesses, preventive care, vaccinations, and health assessments. We provide holistic care to improve overall well-being and coordinate specialist referrals when needed.",
  },
  {
    title: "Pediatrics",
    // services: ["High-Risk Infant ", "Immunisation Checkup"],
    image: "/fc-departments/Paediatrics.png",
    describtion:
      "Our Pediatrics department offers compassionate care for children, focusing on growth, immunizations, and illness management. We provide expert support for asthma, allergies, infections, and developmental needs.",
  },

  {
    title: "Orthopedics",
    // services: ["High-Risk Infant ", "Immunisation Checkup"],
    image: "/fc-departments/Paediatrics.png",
    describtion:
      "Our Orthopedics department treats fractures, arthritis, sports injuries, and musculoskeletal conditions. We offer non-surgical and surgical solutions, physiotherapy, and personalized rehabilitation programs to restore mobility and function.",
  },
  {
    title: "Reproductive Medicine",
    // services: ["General Surgery", "Specialized Surgery"],
    image: "/fc-departments/surgical.png",
    describtion:
      "We specialize in fertility treatments, including IUI, IVF, and fertility preservation. Our team offers personalized care, genetic counselling, and advanced reproductive technologies for individuals and couples.",
  },
  {
    title: "Dermatology",
    // services: ["Oncology", "Cardiology", "Neurology"],
    image: "/fc-departments/speciality-care.png",
    describtion:
      "Our Dermatology department treats skin conditions like acne, eczema, and skin cancer. We also provide cosmetic services, including chemical peels, laser treatments, and anti-ageing therapies for healthy, radiant skin.",
  },
  {
    title: "Homecare Services",
    // services: ["Psychiatry", "Psychology"],
    image: "/fc-departments/mentalhealth.png",
    describtion:
      "Our Homecare Services offer post-surgical care, wound management, physiotherapy, and palliative care at home. Skilled professionals ensure personalized, compassionate support for recovery and improved quality of life.",
  },
];

function FamilyClinic() {
  return (
    <div className={style.wrapper}>
      {/* Banner Section */}
      <div className={style.banner}>
        <Grid
          container
          // paddingTop="10vh"
          height="90vh"
          sx={{
            paddingTop: { xs: "15vh", sm: "15vh", md: "20vh", lg: "25vh" },
          }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Grid className={style.bannerCard}>
              <Typography
                color="#000000"
                fontFamily="Manrope"
                fontWeight="700"
                paddingLeft="10%"
                textTransform="uppercase"
                sx={{
                  fontSize: {
                    xs: "26px",
                    sm: "32px",
                    md: "35px",
                  },
                  lineHeight: { xs: "120%", md: "130%" },
                  letterSpacing: {
                    xs: "-4%",
                    sm: "-2%",
                    md: "-2%",
                  },
                }}>
                Love and Care to Humanity!
              </Typography>
              <Typography
                color="#000000"
                fontFamily="Manrope"
                fontWeight="500"
                paddingLeft="10%"
                paddingRight="15%"
                marginTop="10px"
                letterSpacing="-2%"
                lineHeight="189%"
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                    md: "14px",
                  },
                }}>
                <b>GG Family Clinic </b>, a satellite center of{" "}
                <b>GG Hospital Trivandrum </b>, offers expert care in ENT,
                Dental, Gynecology, Pediatrics, and more. With advanced services
                like X-ray, USG, and Physiotherapy, we provide personalized,
                state-of-the-art healthcare near Technopark, ensuring
                convenience and excellence for the Trivandrum community.
              </Typography>
              <Button
                variant="contained"
                endIcon={<ArrowRightAltIcon />}
                style={{ backgroundColor: "#FFFFFF", color: "black" }}
                sx={{
                  borderRadius: "6px",
                  textTransform: "none",
                  height: "55px",
                  boxShadow: "none",
                  marginLeft: "10%",
                  marginTop: "10px",
                  fontFamily: "Manrope",
                }}>
                Explore More
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
        </Grid>
      </div>

      {/* About Section */}
      <div className={style.about}>
        <Grid container minHeight="100vh" marginTop="40px">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <img src={AboutImage} alt="" width={"70%"} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Grid
              sx={{
                display: { xs: "flex", md: "flex", lg: "block" },
                flexDirection: { xs: "column", md: "column", lg: "column" },
                justifyContent: {
                  xs: "center",
                  md: "center",
                  lg: "flex-start",
                },
                alignItems: { xs: "center", md: "center", lg: "flex-start" },
                marginTop: { xs: "-30px", md: "0px" },
              }}>
              <Chip
                label="About Us"
                sx={{
                  backgroundColor: "#B6E8FF",
                  marginBottom: { xs: "30px", md: "0px" },
                }}
              />
              <Typography
                color="#000000"
                fontFamily="Manrope"
                fontWeight="700"
                letterSpacing="-2%"
                marginTop="5px"
                lineHeight="130%"
                textTransform="uppercase"
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  fontSize: {
                    xs: "26px",
                    sm: "32px",
                    md: "34px",
                  },
                }}>
                Your Trusted <br /> Healthcare Partner
              </Typography>
              <Typography
                color="#000000"
                fontFamily="Manrope"
                fontWeight="500"
                marginTop="10px"
                letterSpacing="-2%"
                lineHeight="189%"
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  paddingRight: {
                    xs: "2%",
                    msFlexDirection: "20%",
                  },
                  paddingLeft: {
                    xs: "2%",
                    md: "0%",
                  },
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                    md: "18px",
                  },
                  lineHeight: {
                    xs: "160%",
                    sm: "189%",
                    md: "189%",
                  },
                }}>
                We combine expert medical services with advanced technology to
                ensure your health and well-being.
              </Typography>
              <Grid sx={{ width: "80%", marginTop: "10px" }}>
                <Grid container justifyContent="center">
                  {services.map((service, index) => (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      key={index}
                      sx={{ marginTop: "10px" }}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid
                          item
                          display="flex"
                          justifyContent="center"
                          alignItems="center">
                          <Avatar
                            sx={{
                              backgroundColor: "#00C8FF",
                              width: {
                                xs: 15,
                                sm: 20,
                                md: 20,
                              },
                              height: {
                                xs: 15,
                                sm: 20,
                                md: 20,
                              },
                            }}>
                            <img src={CheckCircleIcon} />
                          </Avatar>
                          <Typography
                            fontSize="14px"
                            fontFamily="Manrope"
                            fontWeight="500"
                            width="90%"
                            sx={{ paddingLeft: "6px" }}>
                            {service.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Button
                variant="contained"
                endIcon={<ArrowRightAltIcon />}
                style={{ backgroundColor: "#4DC9EE", color: "black" }}
                sx={{
                  borderRadius: "6px",
                  textTransform: "none",
                  boxShadow: "none",
                  marginTop: "15px",
                  fontFamily: "Manrope",
                  height: {
                    xs: "40px",
                    sm: "50px",
                    lg: "55px",
                  },
                  marginTop: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                  },
                }}>
                Know More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* Departments Section */}
      <div
        className={style.department}
        sx={{ marginTop: { xs: "30px", lg: "0px" } }}>
        <Grid container sx={{ marginBottom: "5%", padding: "3%" }}>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ marginTop: "5%" }}>
            <Chip label="Departments" sx={{ backgroundColor: "#B6E8FF" }} />
            <Typography
              color="#000000"
              fontFamily="Manrope"
              fontSize="34px"
              fontWeight="700"
              letterSpacing="-2%"
              marginTop="7px"
              lineHeight="130%"
              textTransform="uppercase"
              sx={{
                textAlign: { xs: "center", md: "c" },
                fontSize: {
                  xs: "26px",
                  sm: "32px",
                  md: "34px",
                },
              }}>
              Comprehensive Care Across
            </Typography>
            <Typography
              color="#000000"
              fontFamily="Manrope"
              fontSize="18px"
              fontWeight="500"
              marginTop="10px"
              letterSpacing="-2%"
              lineHeight="189%"
              textAlign="center"
              sx={{
                textAlign: { xs: "center", md: "c" },
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                  md: "18px",
                },
              }}>
              We offer a wide range of medical specialties to meet all of your{" "}
              <br /> healthcare needs under one roof
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            spacing={2}
            sx={{
              paddingLeft: { xs: "20px", md: "40px", lg: "50px" },
              paddingRight: { xs: "20px", md: "40px", lg: "50px" },
            }}>
            <Grid
              container
              spacing={5}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}>
              {departments.map((department, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  key={index}
                  sx={{ margin: { xs: "2%", sm: "0%", md: "" } }}>
                  <Grid
                    container
                    xs={12}
                    className={style.card}
                    sx={{
                      backgroundColor: "white",
                      padding: { xs: "4%", sm: "2%", md: "2%" },
                      borderRadius: "5px",
                    }}>
                    <Grid item xs={12}>
                      <Typography
                        color="#000000"
                        fontFamily="Manrope"
                        fontSize="20px"
                        fontWeight="600"
                        letterSpacing="-2%"
                        padding="5px"
                        lineHeight="130%">
                        {department.title}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
											<Grid sx={{ marginTop: "0px" }}>
												<Grid container justifyContent="flex-start">
													{department.services.map((service, index) => (
														<Grid
															item
															xs={6}
															sm={4}
															key={index}
															sx={{ marginTop: "5px", display: "flex" }}>
															<Grid
																container
																alignItems="center"
																// justifyContent="center"
																spacing={1}>
																<Grid
																	item
																	display="flex"
																	justifyContent="center"
																	alignItems="center">
																	<Avatar
																		sx={{
																			backgroundColor: "#00C8FF",
																			width: 15,
																			height: 15,
																		}}>
																		<img src={CheckCircleIcon} />
																	</Avatar>
																	<Typography
																		fontSize="12px"
																		fontFamily="Manrope"
																		fontWeight="500"
																		width="90%"
																		sx={{ paddingLeft: "6px" }}>
																		{service}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
													))}
												</Grid>
											</Grid>
										</Grid> */}

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      sx={{ marginTop: "15px" }}
                      justifyContent="center"
                      alignItems="center">
                      <FcHoverImage
                        image={department.image}
                        width={"100%"}
                        height={"auto"}
                        // btnName={"Read More"}
                        describtion={department.describtion}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default FamilyClinic;
